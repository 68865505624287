<template>
  <AccountUnverified v-if="!user.email_verified_at" />
  <div class="Addons" v-else-if="this.user.subscription_plan.name !== 'Free' && !this.user.is_on_old_plan">
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <form ref="form"
            @submit.prevent="passes(onFormSubmit)">

        <h2>
          Additional Services
        </h2>
        <base-alert type="error" v-if="monitorAddError">
          Something went wrong
        </base-alert>

        <div class="new-addon">
          <base-textbox placeholder="Number of Monitors"
                        type="Number"
                        v-model="monitorsQuantity"
                        label="MONITORS ($0.2 / PER MONITOR / MO)"
                        rules="min_value:0"/>
          <br>
          <base-textbox placeholder="Number of Teammates"
                        type="Number"
                        v-model="teammatesQuantity"
                        label="TEAMMATES ($8 / PER USER / MO)"
                        rules="min_value:0"/>
          <br>
          <base-textbox placeholder="Number of sms/calls alerts"
                        type="Number"
                        v-model="smsCallsQuantity"
                        label="SMS/CALL ALERTS ($0,1 / PER ALERT / MO)"
                        rules="min_value:0"/>
          <br>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <label for="notifyOnlySeatsQuantity" class="label">NOTIFY-ONLY SEATS ($2 / PER SEAT / MO)</label>
            <img ref="tooltipNotifySeats" class="label" :src="require('/public/help.svg')" style="margin-left: 5px; height: 20px; width: 20px" />
          </div>
          <base-textbox placeholder="Number of Notify Only Seats"
                        type="Number"
                        v-model="notifyOnlySeatsQuantity"
                        id="notifyOnlySeatsQuantity"
                        rules="min_value:0"/>
          <br>
          <base-textbox placeholder="Number of Status Page Subscribers"
                        type="Number"
                        v-model="subscribersQuantity"
                        label="STATUS PAGE SUBSCRIBERS ($0,01 / PER SUBSCRIBER / MO)"
                        rules="min_value:0"/>
          <br>
          <div class="description">
            <p style="float: left">Additional Monitors</p>
            <p style="float: right"><strong>${{ monitorsQuantity * monitorPrice >= 0 ? parseFloat(monitorsQuantity * monitorPrice).toFixed(2) : parseFloat(0).toFixed(2) }}</strong></p>
          </div>
          <br>
          <div class="description">
            <p style="float: left">Additional Teammates</p>
            <p style="float: right"><strong>${{ teammatesQuantity * teammatePrice >= 0 ? parseFloat(teammatesQuantity * teammatePrice).toFixed(2) : parseFloat(0).toFixed(2) }}</strong></p>
          </div>
          <br>
          <div class="description">
            <p style="float: left">Additional Sms/Calls</p>
            <p style="float: right"><strong>${{ smsCallsQuantity * smsCallPrice >= 0 ? parseFloat(smsCallsQuantity * smsCallPrice).toFixed(2) : parseFloat(0).toFixed(2) }}</strong></p>
          </div>
          <br>
          <div class="description">
            <p style="float: left">Additional Notify Only Seats</p>
            <p style="float: right"><strong>${{ notifyOnlySeatsQuantity * notifyOnlySeatPrice >= 0 ? parseFloat(notifyOnlySeatsQuantity * notifyOnlySeatPrice).toFixed(2) : parseFloat(0).toFixed(2) }}</strong></p>
          </div>
          <br>
          <div class="description">
            <p style="float: left">Additional Status Page Subscribers</p>
            <p style="float: right"><strong>${{ subscribersQuantity * statusPageSubscriberPrice >= 0 ? parseFloat(subscribersQuantity * statusPageSubscriberPrice).toFixed(2) : parseFloat(0).toFixed(2) }}</strong></p>
          </div>
          <br>
          <br>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <base-button color="primary"
                         type="Number"
                         :loading="submitting"
                         @click.prevent="onFormSubmit">Checkout</base-button>
            <p v-if="totalPrice > 0" style="font-size: 18px;"><strong>${{totalPrice}}</strong></p>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Upgrade your plan to access this feature."/>
    </div>
  </div>
</template>

<script>
import subscriptionsApi from '@/api/subscriptionsApi'
import tippy from 'tippy.js'
import UpgradeBadge from '@/components/Common/UpgradeBadge.vue'

export default {
  components: {
    UpgradeBadge
  },

  data () {
    return {
      monitorAddError: false,
      monitorPrice: 0.2,
      smsCallPrice: 0.1,
      teammatePrice: 8,
      notifyOnlySeatPrice: 2,
      statusPageSubscriberPrice: 0.01,
      monitorsQuantity: 0,
      smsCallsQuantity: 0,
      subscribersQuantity: 0,
      notifyOnlySeatsQuantity: 0,
      teammatesQuantity: 0,
      submitting: false,
      extraMonitorsAddon: null,
      extraSmsCallsAddon: null,
      extraNotifySeatsAddon: null,
      extraTeammatesAddon: null,
      extraStatusPageSubscribersAddon: null,
      addons: {}
    }
  },

  created () {
    this.getAddonsItems()
    this.getUserAddonsNumber()
  },

  methods: {
    async getUserAddonsNumber () {
      this.monitorsQuantity = this.user.limits ? this.user.limits.monitors - (this.user.subscription_plan.monitors_no ?? 0) : 0
      this.smsCallsQuantity = this.user.limits ? this.user.limits.sms_calls - (this.user.subscription_plan.sms_voice_alerts ?? 0) : 0
      this.subscribersQuantity = this.user.limits ? this.user.limits.status_page_subscribers - (this.user.subscription_plan.subscribers_no ?? 0) : 0
      this.teammatesQuantity = this.user.limits ? this.user.limits.teammates - (this.user.subscription_plan.teammates ?? 0) : 0
      // @todo check if it is ok
      this.notifyOnlySeatsQuantity = this.user.limits ? this.user.limits.notify_only_seats - (this.user.subscription_plan.notify_only_seats ?? 0) : 0
    },

    async getAddonsItems () {
      const addonsItems = await subscriptionsApi.getAddonsItems()
      for (let index in addonsItems) {
        let stripeItemName = addonsItems[index].name.toLowerCase()
        if (stripeItemName.includes('monitor')) {
          this.extraMonitorsAddon = addonsItems[index].stripe_item
        } else if (stripeItemName.includes('sms')) {
          this.extraSmsCallsAddon = addonsItems[index].stripe_item
        } else if (stripeItemName.includes('teammate')) {
          this.extraTeammatesAddon = addonsItems[index].stripe_item
        } else if (stripeItemName.includes('subscribe')) {
          this.extraStatusPageSubscribersAddon = addonsItems[index].stripe_item
        } else if (stripeItemName.includes('seat')) {
          this.extraNotifySeatsAddon = addonsItems[index].stripe_item
        }
      }
    },

    async onFormSubmit () {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        return false
      }

      if (this.smsCallsQuantity) {
        this.addons[this.extraSmsCallsAddon] = this.smsCallsQuantity
      }
      if (this.monitorsQuantity) {
        this.addons[this.extraMonitorsAddon] = this.monitorsQuantity
      }
      if (this.subscribersQuantity) {
        this.addons[this.extraStatusPageSubscribersAddon] = this.subscribersQuantity
      }
      if (this.notifyOnlySeatsQuantity) {
        this.addons[this.extraNotifySeatsAddon] = this.notifyOnlySeatsQuantity
      }
      if (this.teammatesQuantity) {
        this.addons[this.extraTeammatesAddon] = this.teammatesQuantity
      }
      await this.redirectToPayment(this.addons)
    },

    async redirectToPayment (addons) {
      try {
        const response = await subscriptionsApi.getStripeAddonSessionUrl(addons)
        window.open(response['checkout_url'])
      } catch (e) {
        this.error = e
      }
    }
  },
  computed: {
    ...mapState('authentication', ['user']),

    totalPrice () {
      return (this.monitorsQuantity * this.monitorPrice + this.teammatesQuantity * this.teammatePrice + this.smsCallsQuantity * this.smsCallPrice + this.notifyOnlySeatsQuantity * this.notifyOnlySeatPrice + this.subscribersQuantity * this.statusPageSubscriberPrice).toFixed(2)
    }
  },

  mounted () {
    tippy(this.$refs.tooltipNotifySeats, {
      content: 'Notify your customers or team members without granting them access to your dashboard. Each seat comes with email, SMS, and voice call alert options.',
      interactive: true
    })
  }
}
</script>

<style lang="scss" scoped>
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media (max-width: 767px) {
    margin: 24px 0;
    min-height: unset !important;
  }
}

.Addons {
  max-width: 550px;

  .description {
    margin-bottom: 10px;
    color: #777;
    line-height: 1.5;
  }

  .addons-list {
    max-width: 550px;
    align-items: center;

    .addon {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover /deep/ svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 5px;
      }
    }
  }

  .new-addon {
    .base-text-box {
      flex-grow: 4;
      margin: 0;
    }

    .add {
      margin: 0 0 0 10px;
    }
  }

  .button-update {
    width: 200px;
  }

  .already-added {
    margin-top: 10px;
  }

  .label {
    display: block;
    margin-bottom: 10px;
    //color: map-get($colors, gray-1);
    // font-size: 15px;
    // font-weight: 400;
    // letter-spacing: -0.009em;
    opacity: .8;

    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-feature-settings: "ss04" off;
  }
}
</style>
