<template>
  <div class="card">
    <div class="card-body" style="width: 60%">
      <Addons />
    </div>
  </div>
</template>

<script>
import Addons from '@/components/Account/Addons/Addons.vue'

export default {
  components: { Addons }
}
</script>
